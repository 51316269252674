.Wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 7rem;
  z-index: 101;
  background: #284255;
}

@media (prefers-color-scheme: dark) {
  .Wrapper {
    background: #222;
  }
}

.Logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 7rem;
  min-height: 7rem;
  background: #196F21;
  img {
    width:  4.2rem;
  }
}

@media (prefers-color-scheme: dark) {
  .Logo {
    background: #222;
  }
}

.Menu {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 2rem 1.5rem;
}

.Link {
  width: 100%;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #4B6070;
  border-radius: .5rem;
  margin-bottom: 1.25rem;
  &:last-child {
    margin-bottom: 0;
  }
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
  img {
    max-width: 70%;
    max-height: 70%
  }
  span {
    position: absolute;
    right: -1rem;
    color: #fff;
    font-size: 1.2rem;
    white-space: nowrap;
    background: #183144;
    opacity: 0;
    transform: translateX(80%);
    padding: .75rem 1rem .65rem 1rem;
    border-radius: .5rem;
    pointer-events: none;
    transition: all ease-in .25s;
    &:after {
      content: '';
      position: absolute;
      left: -.5rem;
      width: 0;
      height: 0;
      border-top: .5rem solid transparent;
      border-right: .5rem solid #183144;
      border-bottom: .5rem solid transparent;
    }
  }
  &:hover {
    span {
      opacity: 1;
      transform: translateX(100%);
      transition: all ease-in .25s;
    }
  }
}

@media (prefers-color-scheme: dark) {
  .Link {
    background: inherit;
    span {
      background: #9a8e69;
      &:after {
        border-top: .5rem solid transparent;
        border-right: .5rem solid #9a8e69;
        border-bottom: .5rem solid transparent;
      }
    }
  }
}

.Logout {
  position: absolute;
  bottom: 1.5rem;
  width: 4rem;
  background: #f75b5b;
}

@media (prefers-color-scheme: dark) {
  .Logout {
    background: #181818;
  }
}