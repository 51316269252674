.Radio {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  user-select: none;
  cursor: pointer;
  font-size: 2rem;
  color: #2c0b02;
  padding: .5rem 0;
}

@media (prefers-color-scheme: dark) {
  .Radio {
    color: #fff;
  }
}


.Input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  &:checked + .Control {
    background: #caeace;
    img {
      transform: scale(1);
    }
  }

  &:disabled + .Control {
    color: #2c0b02;
  }
}


.Label {
  width: calc(100% - 4rem);
  display: flex;
  align-items: center;
  margin-left: 1rem;
  color: #333;
  line-height: 135%;
  font-size: 1.3rem;
  a {
    color: #197323;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

@media (prefers-color-scheme: dark) {
  .Label {
    color: #fff;
  }
}

.Wrapper {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
}

.Control {
  display: block;
  position: relative;
  top: .2rem;
  width: 2rem;
  height: 2rem;
  border-radius: 2.5px;
  border: 1px solid #D3D3D3;

  img {
    position: absolute;
    top: -.5rem;
    left: .15rem;
    max-width: 2.2rem;
    transition: transform 0.1s ease-in 25ms;
    transform: scale(0);
    transform-origin: bottom left;
  }
}



.Error {
  .Control {
    border: 1px solid red;
  }
  .Message {
    width:  100%;
    display: block;
    color: red;
    padding-left: 3.75rem;
    padding-top: .5rem;
    font-size: 1.2rem;
  }
}


.Centered {
  display: flex;
  align-items: center;
}