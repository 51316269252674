.Item {
  margin-bottom: 1.5rem;
  &:last-child {
    margin-bottom: 0;
  }
  h3 {
    font-size: 1.3rem;
    font-weight: 600;
  }
  p {
    margin-top: .25rem;
    font-size: 1.2rem;
    &.Paid {
      color: #8bc34a;
    }
    &.Active {
      color: #196f21;
    }
    &.Coming {
      color: orange;
    }
    &.End {
      color: red;
    }
    &.Green {
      color: #8bc34a;
    }
    &.Orange {
      color: orange;
    }
  }
}