.Main {
  position: relative;
  margin-left: 7rem;
  background: #EBEFF3;
  min-width: calc(100% - 7rem);
  min-height: 100vh;
}

@media (prefers-color-scheme: dark) {
  .Main {
    background: #181818;
    h1 {
      color: #fff;
    }
  }
}

.Content {
  position: relative;
  padding: 1rem;
  min-height: 100%;
}

.Header {
  position: relative;
  z-index: 99;
  height: 7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  background: #FFFFFF;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.05);
  h1 {
    color: #394955;
    font-size: 1.8rem;
    span {
      color: #196F21;
    }
  }
}

@media (prefers-color-scheme: dark) {
  .Header {
    background: #222;
    h1 {
      color: #fff;
    }
  }
}

.Loading {
  position: fixed;
  background: #EBEFF3;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:  98;
}

.User {
  display: flex;
  color: #394955;
  font-size: 1.3rem;
  ul {
    li {
      list-style: none;
      line-height: 150%;
      span {
        width: 7rem;
        font-weight: 600;
        display: inline-block;
      }
    }
  }
}

@media (prefers-color-scheme: dark) {
  .User {
    color: #fff;
    opacity: .7;
  }
}

.Controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f7f9;
  height: 56px;
  border-bottom: 1px #cfd7df solid;
  padding: 0 15px;
  padding-right: 16rem;
  box-shadow: 0 2px 4px 0 rgba(24,50,71,.08);
}

@media (prefers-color-scheme: dark) {
  .Controls {
    background: #181818;
    border-bottom: 1px #232323 solid;
    box-shadow: 0 2px 4px 0 rgba(10, 10, 10,.5);
  }
}

.ControlsEnd {
  justify-content: flex-end;
}

