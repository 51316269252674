.Nav {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #cad2da;
  padding: 1.5rem 0;
  padding-bottom: 1rem;
  button {
    display: block;
    border: none;
    background: none;
    color: #284255;
    font-weight: 600;
    font-size: 1.5rem;
    &.Active {
      font-size: 1.8rem;
    }
  }
}

@media (prefers-color-scheme: dark) {
  .Nav {
    border-bottom: 1px solid #3a3939;
    button {
      color: #fff;
    }
  }
}

.Wrapper {
  margin-top: 2rem;
  background: #fff;
  padding: 3rem 2rem;
}

@media (prefers-color-scheme: dark) {
  .Wrapper {
    background: #222;
    h3 {
      color: #fff;
    }
  }
}

.Item {
    h3 {
      font-size: 1.6rem;
    }
}
