.Search {
  position: relative;
  height: 32px;
  width: 30rem;
  input {
    border: 1px solid #cfd7df;
    border-radius: 4px;
    padding: 4px 8px;
    padding-left: 3rem;
    color: #92a2b1;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  img {
    position: absolute;
    left: 1rem;
    top: 0;
    bottom: 0;
    z-index: 1;
    margin: auto;
  }
}

@media (prefers-color-scheme: dark) {
  .Search {
    input {
      background: #222;
      border: 1px solid #333;
    }
  }
}