.Item {
  position: relative;
  cursor: pointer;
  padding: 2rem;
  background: #fff;
  border-radius: .5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0px 1px 0px rgba(207, 215, 223, 0.25);
  &:hover {
    box-shadow: 0px 2px 8px 4px rgba(207, 215, 223, 0.5);
  }
  img {
    position: absolute;
    right: 1.5rem;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  h3 {
    font-size: 1.4rem;
    font-weight: 600;
    color: #394955;
    &.Password {
      color: #8bc34a;
    }
    &.Invoice {
      color: orange;
    }
    &.Active {
      color: #196F21;
    }
    &.Coming {
      color: #795548;
    }
    &.End {
      color: red;
    }
    &.Archive {
      color: #97a0a7;
    }
  }
  p {
    margin-top: 1rem;
    color: #196F21;
    font-weight: 600;
    font-size: 2.7rem;
    &.Password {
      color: #8bc34a;
    }
    &.Invoice {
      color: orange;
    }
    &.Active {
      color: #196F21;
    }
    &.Coming {
      color: #795548;
    }
    &.End {
      color: red;
    }
    &.Archive {
      color: #97a0a7;
    }
  }
}

@media (prefers-color-scheme: dark) {
  .Item {
    background: #212121;
    box-shadow: 0px 1px 0px rgba(10, 10, 10, 0.6);
    &:hover {
      box-shadow: 0px 2px 8px 4px rgba(10, 10, 10, 0.5);
    }
  }
}

.Item30 {
  width: calc(33.333% - 1.5rem);
  margin-left: 1.5rem;
}

.Item30 {
  width: calc(33.333% - 1.5rem);
  margin-left: 1.5rem;
}

