@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

body {
  margin: 0;
  font-size: 1.4rem;
  font-family: 'Roboto', sans-serif;
}

@media (prefers-color-scheme: dark) {
  body {
    background: #181818;
  }
}


#root {
  position: relative;
  min-width: 100%;
  min-height: 100vh;
}

.react-datepicker {
  &__input-container {
    width: 100%;
  }

  &-wrapper {
    width: 100%;

    input {
      border: 1px solid #D3D3D3;
      width: 100%;
      height: 3.9rem;
      color: #333;
      font-size: 1.4rem;
      font-weight: 600;
      border-radius: .25rem;
      padding: 0 1.25rem;
      display: block;
    }
  }
}

.loading {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 100;
  background: #EBEFF3;
  height: calc(100% - 12.6rem);
}

@media (prefers-color-scheme: dark) {
  .loading {
    background: #212121;
  }
}


.__react_component_tooltip {
  padding: 5px 12px !important;
  font-size: 12px !important;
}