.Label {
  width:  100%;
  cursor: pointer;
  display: block;
  font-size: 1.3rem;
  font-weight: 600;
  color: #333;
  margin-bottom: .5rem;
}

@media (prefers-color-scheme: dark) {
  .Label {
    color: #fff;
  }
}