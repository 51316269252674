.Wrapper {
  width: 100%;
  display: block;
  position: relative;
  margin-top: 1.5rem;
}



.Label {
  display: block;
  color: #333;
  font-weight: 600;
  font-size: 1.3rem;
  margin-bottom: .5rem;
}

@media (prefers-color-scheme: dark) {
  .Label {
    color: #fff;
    opacity: .6;
  }
}

.Error {
  input {
    border: 1px solid red;
  }
  span {
    color: red;
    display: block;
    margin-top: .5rem;
    font-size: 1.3rem;
  }
}


