.Button {
  border: none;
  cursor: pointer;
  font-weight: 600;
  border-radius: 5px;
  text-transform: uppercase;
  box-shadow: 0px 2px 4px rgba(22, 111, 37, 0.2);
}

.Green {
  color: #fff;
  background: #197323;
}

@media (prefers-color-scheme: dark) {
  .Green {
    background: #9a8e69;
  }
}

.Red {
  color: #fff;
  background: red;
}

.Small {
  font-size: 1rem;
  padding:  0 1rem;
  height: 3rem;
}

.Normal {
  font-size: 1.2rem;
  padding:  0 2rem;
  height: 3.5rem;
}

.Big {
  font-size: 1.4rem;
  padding:  0 2.5rem;
  height: 4rem;
}



.Random {
  color: #555;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  position: absolute;
  right: .5rem;
  bottom: .5rem;
  text-transform: none;
  background: #d0d0d0;
  padding: 0 1rem;
  height: calc(100% - 3rem);
  img {
    width: 1.6rem;
    margin-right: .5rem;
  }
}