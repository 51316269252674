.Container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: rgba(0,0,0,0.6);
}

.Wrapper {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 10rem 1rem;
  overflow-y: auto;
  height: 100%;
}

.Item {
  width: 45rem;
  margin: 0 auto;
  position: relative;
  padding: 2.5rem 3rem;
  background: #fff;
}

@media (prefers-color-scheme: dark) {
  .Item {
    background: #1f1f1f;
  }
}

.Title {
  margin: 0;
  color: #196F21;
  font-weight: 600;
  font-size: 2.1rem;
  white-space: pre-line;
}

@media (prefers-color-scheme: dark) {
  .Title {
    color: #9a8e69;
  }
}

.Desc {
  color: #333;
  font-size: 1.3rem;
  margin-top: 1rem;
}

@media (prefers-color-scheme: dark) {
  .Desc {
    color: #fff;
    opacity: .5;
  }
}

.Close {
  cursor: pointer;
  border: none;
  position: absolute;
  top: 2rem;
  right: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background: transparent;
  img {
    width: 100%;
  }
}