.Info {
  padding: 2rem;
  border-radius: .5rem;
  background: #FFFFFF;
  box-shadow: 0px 1px 0px rgba(207, 215, 223, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
}

@media (prefers-color-scheme: dark) {
  .Info {
    background: #222;
    box-shadow: 0px 1px 0px rgba(207, 215, 223, 0.25);
  }
}

.User {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    span {
      display: block;
      color: #394955;
      font-size: 1.3rem;
    }
    p {
      margin-top: .5rem;
      color: #394955;
      font-weight: 600;
      font-size: 1.5rem;
      max-width: 40rem;
    }
  }
}

@media (prefers-color-scheme: dark) {
  .User {
    div {
      span, p {
        color: #fff;
      }
    }
  }
}

.Controls {
  display: flex;
}

.Buttons {
  padding: 0 0 2rem;
}