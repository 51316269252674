@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
/* Document
 * ========================================================================== *//**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}/* Sections
 * ========================================================================== *//**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */h1 {
  font-size: 2em;
  margin: 0.67em 0;
}/* Grouping content
 * ========================================================================== *//**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}/**
 * Add the correct display in IE.
 */main {
  display: block;
}/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}/* Text-level semantics
 * ========================================================================== *//**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */b,
strong {
  font-weight: bolder;
}/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}/**
 * Add the correct font size in all browsers.
 */small {
  font-size: 80%;
}/* Embedded content
 * ========================================================================== *//**
 * Hide the overflow in IE.
 */svg:not(:root) {
  overflow: hidden;
}/* Forms
 * ========================================================================== *//**
 * Remove the margin on controls in Safari.
 */button,
input,
select {
  margin: 0;
}/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}/**
 * Correct the inability to style buttons in iOS and Safari.
 */button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}/**
 * Correct the padding in Firefox.
 */fieldset {
  padding: 0.35em 0.75em 0.625em;
}/**
 * Show the overflow in Edge 18- and IE.
 */input {
  overflow: visible;
}/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}/**
 * Remove the inheritance of text transform in Firefox.
 */select {
  text-transform: none;
}/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */::-webkit-search-decoration {
  -webkit-appearance: none;
}/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */::-moz-focus-inner {
  border-style: none;
  padding: 0;
}/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */:-moz-focusring {
  outline: 1px dotted ButtonText;
}/**
 * Remove the additional :invalid styles in Firefox.
 */:-moz-ui-invalid {
  box-shadow: none;
}/* Interactive
 * ========================================================================== *//*
 * Add the correct display in Edge 18- and IE.
 */details {
  display: block;
}/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}dialog:not([open]) {
  display: none;
}/*
 * Add the correct display in all browsers.
 */summary {
  display: list-item;
}/* Scripting
 * ========================================================================== *//**
 * Add the correct display in IE.
 */template {
  display: none;
}/* User interaction
 * ========================================================================== */*{margin:0;padding:0;box-sizing:border-box}html{font-size:10px}body{margin:0;font-size:1.4rem;font-family:"Roboto",sans-serif}@media(prefers-color-scheme: dark){body{background:#181818}}#root{position:relative;min-width:100%;min-height:100vh}.react-datepicker__input-container{width:100%}.react-datepicker-wrapper{width:100%}.react-datepicker-wrapper input{border:1px solid #d3d3d3;width:100%;height:3.9rem;color:#333;font-size:1.4rem;font-weight:600;border-radius:.25rem;padding:0 1.25rem;display:block}.loading{position:fixed;left:0;right:0;bottom:0;display:flex;justify-content:center;align-items:center;width:100%;z-index:100;background:#ebeff3;height:calc(100% - 12.6rem)}@media(prefers-color-scheme: dark){.loading{background:#212121}}.__react_component_tooltip{padding:5px 12px !important;font-size:12px !important}
.Page_Main__1bZzD{position:relative;margin-left:7rem;background:#ebeff3;min-width:calc(100% - 7rem);min-height:100vh}@media(prefers-color-scheme: dark){.Page_Main__1bZzD{background:#181818}.Page_Main__1bZzD h1{color:#fff}}.Page_Content__1LSxg{position:relative;padding:1rem;min-height:100%}.Page_Header__o5SmE{position:relative;z-index:99;height:7rem;display:flex;align-items:center;justify-content:space-between;padding:0 2rem;background:#fff;box-shadow:0px 2px 30px rgba(0,0,0,.05)}.Page_Header__o5SmE h1{color:#394955;font-size:1.8rem}.Page_Header__o5SmE h1 span{color:#196f21}@media(prefers-color-scheme: dark){.Page_Header__o5SmE{background:#222}.Page_Header__o5SmE h1{color:#fff}}.Page_Loading__2al-6{position:fixed;background:#ebeff3;top:0;right:0;left:0;bottom:0;width:100%;height:100%;display:flex;align-items:center;justify-content:center;z-index:98}.Page_User__3H2eQ{display:flex;color:#394955;font-size:1.3rem}.Page_User__3H2eQ ul li{list-style:none;line-height:150%}.Page_User__3H2eQ ul li span{width:7rem;font-weight:600;display:inline-block}@media(prefers-color-scheme: dark){.Page_User__3H2eQ{color:#fff;opacity:.7}}.Page_Controls__3GVky{display:flex;align-items:center;justify-content:space-between;background-color:#f5f7f9;height:56px;border-bottom:1px #cfd7df solid;padding:0 15px;padding-right:16rem;box-shadow:0 2px 4px 0 rgba(24,50,71,.08)}@media(prefers-color-scheme: dark){.Page_Controls__3GVky{background:#181818;border-bottom:1px #232323 solid;box-shadow:0 2px 4px 0 rgba(10,10,10,.5)}}.Page_ControlsEnd__3nEyq{justify-content:flex-end}
.Paginator_Pagination__Ln_39{position:absolute;right:1.5rem;display:flex;align-items:center}@media(prefers-color-scheme: dark){.Paginator_Pagination__Ln_39{color:#fff;opacity:.6}}.Paginator_Info__2JtX_{font-size:1.3rem;margin-right:1.5rem}.Paginator_Buttons__3-rQC{display:flex;margin-left:1rem}.Paginator_Button__1pPWf{height:3.2rem;min-width:3.2rem;border-radius:4px;border:1px solid #cfd7df;color:#12344d;cursor:pointer;margin-right:.5rem;font-size:14px;vertical-align:middle;-webkit-font-smoothing:antialiased;transition:.1s ease-in;background-image:linear-gradient(to bottom, #fff, #f3f5f7);display:flex;align-items:center;justify-content:center}.Paginator_Button__1pPWf img{width:1.2rem}@media(prefers-color-scheme: dark){.Paginator_Button__1pPWf{background:linear-gradient(180deg, #222222 0%, #222222 100%);border:1px solid #181818}}.Paginator_Prev__3Voeh img{transform:rotate(180deg)}
.Home_Wrapper__2iJR4{display:flex;flex-wrap:wrap;margin-left:-1.5rem}
.Informer_Item__3VSz1{position:relative;cursor:pointer;padding:2rem;background:#fff;border-radius:.5rem;margin-bottom:1.5rem;box-shadow:0px 1px 0px rgba(207,215,223,.25)}.Informer_Item__3VSz1:hover{box-shadow:0px 2px 8px 4px rgba(207,215,223,.5)}.Informer_Item__3VSz1 img{position:absolute;right:1.5rem;top:0;bottom:0;margin:auto}.Informer_Item__3VSz1 h3{font-size:1.4rem;font-weight:600;color:#394955}.Informer_Item__3VSz1 h3.Informer_Password__17GI5{color:#8bc34a}.Informer_Item__3VSz1 h3.Informer_Invoice__SFvd7{color:orange}.Informer_Item__3VSz1 h3.Informer_Active__3L0nS{color:#196f21}.Informer_Item__3VSz1 h3.Informer_Coming__1dy2M{color:#795548}.Informer_Item__3VSz1 h3.Informer_End__2a2dT{color:red}.Informer_Item__3VSz1 h3.Informer_Archive__3Hsz8{color:#97a0a7}.Informer_Item__3VSz1 p{margin-top:1rem;color:#196f21;font-weight:600;font-size:2.7rem}.Informer_Item__3VSz1 p.Informer_Password__17GI5{color:#8bc34a}.Informer_Item__3VSz1 p.Informer_Invoice__SFvd7{color:orange}.Informer_Item__3VSz1 p.Informer_Active__3L0nS{color:#196f21}.Informer_Item__3VSz1 p.Informer_Coming__1dy2M{color:#795548}.Informer_Item__3VSz1 p.Informer_End__2a2dT{color:red}.Informer_Item__3VSz1 p.Informer_Archive__3Hsz8{color:#97a0a7}@media(prefers-color-scheme: dark){.Informer_Item__3VSz1{background:#212121;box-shadow:0px 1px 0px rgba(10,10,10,.6)}.Informer_Item__3VSz1:hover{box-shadow:0px 2px 8px 4px rgba(10,10,10,.5)}}.Informer_Item30__eDbHD{width:calc(33.333% - 1.5rem);margin-left:1.5rem}.Informer_Item30__eDbHD{width:calc(33.333% - 1.5rem);margin-left:1.5rem}
.User_Info__2seLh{padding:2rem;border-radius:.5rem;background:#fff;box-shadow:0px 1px 0px rgba(207,215,223,.25);display:flex;justify-content:space-between;align-items:center;margin-bottom:3rem}@media(prefers-color-scheme: dark){.User_Info__2seLh{background:#222;box-shadow:0px 1px 0px rgba(207,215,223,.25)}}.User_User__IqsQF{width:80%;display:flex;align-items:center;justify-content:space-between}.User_User__IqsQF div span{display:block;color:#394955;font-size:1.3rem}.User_User__IqsQF div p{margin-top:.5rem;color:#394955;font-weight:600;font-size:1.5rem;max-width:40rem}@media(prefers-color-scheme: dark){.User_User__IqsQF div span,.User_User__IqsQF div p{color:#fff}}.User_Controls__1Mjbt{display:flex}.User_Buttons__1ZiPY{padding:0 0 2rem}
.ButtonIcon_Button__1Flyg{cursor:pointer;display:flex;align-items:center;justify-content:center;width:3.2rem;height:3.2rem;box-sizing:border-box;border-radius:2.5px;margin-right:.5rem}.ButtonIcon_Button__1Flyg img{max-width:1.4rem}.ButtonIcon_Button__1Flyg:last-child{margin-right:0}.ButtonIcon_Grey__1bJE0{background:linear-gradient(180deg, #FFFFFF 0%, #F3F5F7 100%);border:1px solid #cfd7df}.ButtonIcon_Grey__1bJE0:hover{background:linear-gradient(180deg, #FFFFFF 0%, #dedede 100%)}@media(prefers-color-scheme: dark){.ButtonIcon_Grey__1bJE0{background:linear-gradient(180deg, #292828 0%, #1b1b1b 100%);border:1px solid #181818}.ButtonIcon_Grey__1bJE0:hover{background:linear-gradient(180deg, #292828 0%, #1b1b1b 100%)}}.ButtonIcon_Green__2Kl-A{background:linear-gradient(180deg, #6b8ba2 0%, #284255 100%);border:1px solid #4b677b}.ButtonIcon_Green__2Kl-A:hover{background:linear-gradient(180deg, #6b8ba2 0%, #213748 100%)}
.Station_Ul__34PhU li{list-style:none}
.Pay_Item__3CsJf{position:relative;display:flex;align-items:center;font-size:1.2rem}.Pay_Item__3CsJf:before{content:"";position:relative;top:-10px;display:block;width:.5rem;height:.5rem;margin-right:.5rem;border-radius:100%}.Pay_Paid__gdnML{color:#196f21}.Pay_Paid__gdnML:before{background:#196f21}.Pay_Paid__gdnML{color:#196f21}.Pay_Paid__gdnML:before{background:#196f21}.Pay_Waiting__34yUQ{color:orange}.Pay_Waiting__34yUQ:before{background:orange}
.Table_Table__tBXHq{width:100%;text-align:left;position:relative;border-collapse:collapse}.Table_Th__dSbfm{top:0;z-index:10;position:-webkit-sticky;position:sticky;background:#f5f7f9;box-shadow:0px 1px 0px rgba(207,215,223,.25);border-radius:2.5px 2.5px 0px 0px;padding:2rem 1rem;font-size:1.3rem}@media(prefers-color-scheme: dark){.Table_Th__dSbfm{color:#9a8d69;background:#222;box-shadow:none;border-bottom:2px solid #181818}}.Table_Td__3Vn6E{padding:1.5rem 1rem;font-size:1.3rem;line-height:135%}.Table_TdImage__2Pu6_{width:8.5rem}.Table_TdImage__2Pu6_ img{display:block;border-radius:.5rem}.Table_TdControls__1V-fb{width:17rem}.Table_TdControls__1V-fb div{display:flex;align-items:center}.Table_Tr__1lMd5{cursor:pointer;background:#fff;border-bottom:1px solid #ebeff3}.Table_Tr__1lMd5:hover{background:#ebeff3}@media(prefers-color-scheme: dark){.Table_Tr__1lMd5{background:#222;color:#fff;border-bottom:2px solid #181818}.Table_Tr__1lMd5:hover{background:#222}}.Table_TrCard__1814d{background:#ecf5ed}@media(prefers-color-scheme: dark){.Table_TrCard__1814d{background:#1d1c1c}}.Table_Checkbox__27bnm{width:5rem}.Table_Image__3WaAq{width:6rem}
.Date_Item__3itsi{margin-bottom:1.5rem}.Date_Item__3itsi:last-child{margin-bottom:0}.Date_Item__3itsi h3{font-size:1.3rem;font-weight:600}.Date_Item__3itsi p{margin-top:.25rem;font-size:1.2rem}.Date_Item__3itsi p.Date_Paid__2dfZW{color:#8bc34a}.Date_Item__3itsi p.Date_Active__xThMX{color:#196f21}.Date_Item__3itsi p.Date_Coming__2v9ge{color:orange}.Date_Item__3itsi p.Date_End__3FRvK{color:red}.Date_Item__3itsi p.Date_Green__3cvBJ{color:#8bc34a}.Date_Item__3itsi p.Date_Orange__lILZ9{color:orange}
.Button_Button__2Ajf-{border:none;cursor:pointer;font-weight:600;border-radius:5px;text-transform:uppercase;box-shadow:0px 2px 4px rgba(22,111,37,.2)}.Button_Green__3-UVv{color:#fff;background:#197323}@media(prefers-color-scheme: dark){.Button_Green__3-UVv{background:#9a8e69}}.Button_Red__1zTmW{color:#fff;background:red}.Button_Small__21qcT{font-size:1rem;padding:0 1rem;height:3rem}.Button_Normal__1x778{font-size:1.2rem;padding:0 2rem;height:3.5rem}.Button_Big__3vXRl{font-size:1.4rem;padding:0 2.5rem;height:4rem}.Button_Random__2wdwu{color:#555;font-size:1.2rem;display:flex;align-items:center;position:absolute;right:.5rem;bottom:.5rem;text-transform:none;background:#d0d0d0;padding:0 1rem;height:calc(100% - 3rem)}.Button_Random__2wdwu img{width:1.6rem;margin-right:.5rem}
.Search_Search__2wNw2{position:relative;height:32px;width:30rem}.Search_Search__2wNw2 input{border:1px solid #cfd7df;border-radius:4px;padding:4px 8px;padding-left:3rem;color:#92a2b1;position:absolute;width:100%;height:100%}.Search_Search__2wNw2 img{position:absolute;left:1rem;top:0;bottom:0;z-index:1;margin:auto}@media(prefers-color-scheme: dark){.Search_Search__2wNw2 input{background:#222;border:1px solid #333}}
.Group_Wrapper__IZEW1{width:100%;display:block;position:relative;margin-top:1.5rem}.Group_Label__2RNe0{display:block;color:#333;font-weight:600;font-size:1.3rem;margin-bottom:.5rem}@media(prefers-color-scheme: dark){.Group_Label__2RNe0{color:#fff;opacity:.6}}.Group_Error__ct_UP input{border:1px solid red}.Group_Error__ct_UP span{color:red;display:block;margin-top:.5rem;font-size:1.3rem}
.Login_Wrapper__3REWv{display:flex;align-items:center;justify-content:center;position:absolute;left:0;right:0;top:0;bottom:0;width:100%;height:100%;background:#197122 url(/static/media/background.72fe44b3.png) no-repeat center center;background-size:cover}.Login_Form__28CAu{width:40rem;background:#fff;padding:3rem 4rem;border-radius:.5rem}.Login_Form__28CAu h2{font-size:2.4rem;font-weight:600;color:#197323;margin-bottom:.75rem}.Login_Form__28CAu p{color:#333;font-size:1.4rem}
.Label_Label__2mI5i{width:100%;cursor:pointer;display:block;font-size:1.3rem;font-weight:600;color:#333;margin-bottom:.5rem}@media(prefers-color-scheme: dark){.Label_Label__2mI5i{color:#fff}}
.Input_Item__3ibYF{border:1px solid #d3d3d3;width:100%;height:3.9rem;color:#333;font-size:1.4rem;font-weight:600;border-radius:.25rem;padding:0 1.25rem}.Input_Item__3ibYF:focus{border:1px solid #333}.Input_Item__3ibYF::-webkit-input-placeholder{color:#afafaf;font-size:1.4rem;font-weight:400}.Input_Item__3ibYF:-ms-input-placeholder{color:#afafaf;font-size:1.4rem;font-weight:400}.Input_Item__3ibYF::-ms-input-placeholder{color:#afafaf;font-size:1.4rem;font-weight:400}.Input_Item__3ibYF::placeholder{color:#afafaf;font-size:1.4rem;font-weight:400}@media(prefers-color-scheme: dark){.Input_Item__3ibYF{border:1px solid #000;background:#121212}}.Input_Message__2emIB{width:100%;display:block;color:red;padding-top:.5rem;font-size:1.2rem}.Input_File__sFeVX{display:flex;cursor:pointer;overflow:hidden;align-items:center;justify-content:space-between;width:100%;color:#afafaf;height:4rem;font-size:1.3rem;font-weight:600;background:#fff;padding-left:1.25rem;border:1px solid #d3d3d3;border-radius:.5rem}.Input_File__sFeVX:after{content:"";position:absolute;left:0;background:#fff}.Input_File__sFeVX:focus{border:2px solid #197323}.Input_File__sFeVX:disabled{cursor:no-drop;background:#ebebeb}.Input_File__sFeVX span{display:flex;align-items:center;justify-content:center;height:100%;color:#222;padding:0 2rem;background:#d3d3d3}.Input_File__sFeVX span img{margin-right:1rem;width:1.85rem;opacity:.4}.Input_FileChanged__1QOOq{color:#333}[type=file]{position:absolute;filter:alpha(opacity=0);opacity:0}.Input_Error__NCFY0 .Input_File__sFeVX{border:1px solid red}.Input_ErrorSelect__30oTh>div{border-color:red}.Input_Error__NCFY0 .Input_Message__2emIB{display:block}.Input_Group__135I6{position:relative;display:block;margin-top:1.25rem}
.Stations_Online__2fOeR{display:inline-block;width:.8rem;height:.8rem;background:#2e9039;border-radius:100%}.Stations_Offline__3dnoY{display:inline-block;width:.8rem;height:.8rem;background:#be4906;border-radius:100%}
.Settings_Nav__1M0JG{width:100%;display:flex;border-bottom:1px solid #cad2da;padding:1.5rem 0;padding-bottom:1rem}.Settings_Nav__1M0JG button{display:block;border:none;background:none;color:#284255;font-weight:600;font-size:1.5rem}.Settings_Nav__1M0JG button.Settings_Active__2tbpO{font-size:1.8rem}@media(prefers-color-scheme: dark){.Settings_Nav__1M0JG{border-bottom:1px solid #3a3939}.Settings_Nav__1M0JG button{color:#fff}}.Settings_Wrapper__3Ew8P{margin-top:2rem;background:#fff;padding:3rem 2rem}@media(prefers-color-scheme: dark){.Settings_Wrapper__3Ew8P{background:#222}.Settings_Wrapper__3Ew8P h3{color:#fff}}.Settings_Item__1nYZ3 h3{font-size:1.6rem}
.Checkbox_Radio__11tMk{display:flex;flex-wrap:wrap;position:relative;-webkit-user-select:none;-ms-user-select:none;user-select:none;cursor:pointer;font-size:2rem;color:#2c0b02;padding:.5rem 0}@media(prefers-color-scheme: dark){.Checkbox_Radio__11tMk{color:#fff}}.Checkbox_Input__3x4gH{position:absolute;opacity:0;width:100%;height:100%}.Checkbox_Input__3x4gH:checked+.Checkbox_Control__3S5q0{background:#caeace}.Checkbox_Input__3x4gH:checked+.Checkbox_Control__3S5q0 img{transform:scale(1)}.Checkbox_Input__3x4gH:disabled+.Checkbox_Control__3S5q0{color:#2c0b02}.Checkbox_Label__2Mru9{width:calc(100% - 4rem);display:flex;align-items:center;margin-left:1rem;color:#333;line-height:135%;font-size:1.3rem}.Checkbox_Label__2Mru9 a{color:#197323;text-decoration:underline}.Checkbox_Label__2Mru9 a:hover{text-decoration:none}@media(prefers-color-scheme: dark){.Checkbox_Label__2Mru9{color:#fff}}.Checkbox_Wrapper__rJAjw{position:relative;width:2.5rem;height:2.5rem}.Checkbox_Control__3S5q0{display:block;position:relative;top:.2rem;width:2rem;height:2rem;border-radius:2.5px;border:1px solid #d3d3d3}.Checkbox_Control__3S5q0 img{position:absolute;top:-0.5rem;left:.15rem;max-width:2.2rem;transition:transform .1s ease-in 25ms;transform:scale(0);transform-origin:bottom left}.Checkbox_Error__3sp3V .Checkbox_Control__3S5q0{border:1px solid red}.Checkbox_Error__3sp3V .Checkbox_Message__2KyFh{width:100%;display:block;color:red;padding-left:3.75rem;padding-top:.5rem;font-size:1.2rem}.Checkbox_Centered__2JEqG{display:flex;align-items:center}
.Subscribe_Wrapper__31Q_B{display:flex;justify-content:space-between;align-items:flex-start}.Subscribe_Right__-2APW{width:30%;background:#fff;border-radius:.5rem;padding:1rem}.Subscribe_Right__-2APW img{display:block;border-radius:.5rem}@media(prefers-color-scheme: dark){.Subscribe_Right__-2APW{background:#1f1e1e}}.Subscribe_Left__2zGGY{width:calc(70% - 1rem)}.Subscribe_LeftItem__2Ey6A{display:flex;justify-content:space-between;background:#fff;border-radius:.5rem;margin-bottom:1.5rem}.Subscribe_LeftItem__2Ey6A:last-child{margin-bottom:0}@media(prefers-color-scheme: dark){.Subscribe_LeftItem__2Ey6A{background:#222}}.Subscribe_Item__1Xns->h3{margin-bottom:1rem;font-size:1.5rem;color:#196f21}.Subscribe_Item__1Xns- p{font-weight:400;font-size:1.3rem}.Subscribe_Item__1Xns- ul li{list-style:none;font-weight:600;line-height:150%;font-size:1.3rem}.Subscribe_Item__1Xns- ul li:last-child{margin-bottom:0}.Subscribe_Item__1Xns- ul li span{font-weight:400;font-size:1.25rem}.Subscribe_Item__1Xns- a{display:block;color:#196f21;font-weight:600;font-size:1.3rem;margin-top:2rem}.Subscribe_Item__1Xns- a:hover{text-decoration:none}@media(prefers-color-scheme: dark){.Subscribe_Item__1Xns->h3{color:#9a8e69}.Subscribe_Item__1Xns- p{color:#fff;opacity:.5}.Subscribe_Item__1Xns- ul li{color:#fff;opacity:.5}}.Subscribe_Date__YVqnW{max-width:20rem;display:flex;justify-content:space-between}.Subscribe_Pay__MBN6O{width:30%;background:#f7f9fb;border-top-right-radius:.5rem;border-bottom-right-radius:.5rem}.Subscribe_Pay__MBN6O .Subscribe_Item__1Xns-{width:100%;padding:2rem}@media(prefers-color-scheme: dark){.Subscribe_Pay__MBN6O{background:#1f1e1e}}.Subscribe_Info__1KAii{width:69%;display:flex;flex-wrap:wrap;justify-content:space-between}.Subscribe_Info__1KAii .Subscribe_Item__1Xns-{width:48%;padding:2rem}
.Csv_Wrapper__2C_gq{display:flex;flex-wrap:wrap;margin-left:-1.5rem;padding:0 1.5em}.Csv_Form__IFQyK{width:100%}
.Navbar_Wrapper__3tdzm{display:flex;align-items:center;flex-direction:column;position:fixed;left:0;top:0;bottom:0;width:7rem;z-index:101;background:#284255}@media(prefers-color-scheme: dark){.Navbar_Wrapper__3tdzm{background:#222}}.Navbar_Logo__2iG0s{display:flex;justify-content:center;align-items:center;width:100%;height:7rem;min-height:7rem;background:#196f21}.Navbar_Logo__2iG0s img{width:4.2rem}@media(prefers-color-scheme: dark){.Navbar_Logo__2iG0s{background:#222}}.Navbar_Menu__12K8x{position:relative;width:100%;height:100%;padding:2rem 1.5rem}.Navbar_Link__1hfMv{width:100%;display:flex;border:none;align-items:center;justify-content:center;position:relative;background:#4b6070;border-radius:.5rem;margin-bottom:1.25rem}.Navbar_Link__1hfMv:last-child{margin-bottom:0}.Navbar_Link__1hfMv:after{content:"";display:block;padding-bottom:100%}.Navbar_Link__1hfMv img{max-width:70%;max-height:70%}.Navbar_Link__1hfMv span{position:absolute;right:-1rem;color:#fff;font-size:1.2rem;white-space:nowrap;background:#183144;opacity:0;transform:translateX(80%);padding:.75rem 1rem .65rem 1rem;border-radius:.5rem;pointer-events:none;transition:all ease-in .25s}.Navbar_Link__1hfMv span:after{content:"";position:absolute;left:-0.5rem;width:0;height:0;border-top:.5rem solid rgba(0,0,0,0);border-right:.5rem solid #183144;border-bottom:.5rem solid rgba(0,0,0,0)}.Navbar_Link__1hfMv:hover span{opacity:1;transform:translateX(100%);transition:all ease-in .25s}@media(prefers-color-scheme: dark){.Navbar_Link__1hfMv{background:inherit}.Navbar_Link__1hfMv span{background:#9a8e69}.Navbar_Link__1hfMv span:after{border-top:.5rem solid rgba(0,0,0,0);border-right:.5rem solid #9a8e69;border-bottom:.5rem solid rgba(0,0,0,0)}}.Navbar_Logout__3VCzL{position:absolute;bottom:1.5rem;width:4rem;background:#f75b5b}@media(prefers-color-scheme: dark){.Navbar_Logout__3VCzL{background:#181818}}
.Modal_Container__37bDZ{position:fixed;top:0;bottom:0;left:0;right:0;z-index:1000;width:100%;height:100%;overflow-y:auto;background:rgba(0,0,0,.6)}.Modal_Wrapper__2HZ3a{position:relative;left:0;right:0;top:0;bottom:0;padding:10rem 1rem;overflow-y:auto;height:100%}.Modal_Item__1Qx8w{width:45rem;margin:0 auto;position:relative;padding:2.5rem 3rem;background:#fff}@media(prefers-color-scheme: dark){.Modal_Item__1Qx8w{background:#1f1f1f}}.Modal_Title__2bVOV{margin:0;color:#196f21;font-weight:600;font-size:2.1rem;white-space:pre-line}@media(prefers-color-scheme: dark){.Modal_Title__2bVOV{color:#9a8e69}}.Modal_Desc__b761m{color:#333;font-size:1.3rem;margin-top:1rem}@media(prefers-color-scheme: dark){.Modal_Desc__b761m{color:#fff;opacity:.5}}.Modal_Close__3iJEq{cursor:pointer;border:none;position:absolute;top:2rem;right:1.5rem;width:1.5rem;height:1.5rem;background:rgba(0,0,0,0)}.Modal_Close__3iJEq img{width:100%}
.ModalPassword_Form__j6mzp{width:100%}
.ModalDelete_Buttons__319UM{margin-top:2rem;display:flex;justify-content:space-between}.ModalDelete_Buttons__319UM button{width:48%}
.ModalInstruction_Form__2IIdV{width:100%;margin-top:3rem}
.ModalRegister_Form__1CkSo{width:100%;margin-top:3rem}
.ModalStation_Form__1FoE8{width:100%;margin-top:3rem}
.ModalMigrate_Form__23GPW{width:100%;margin-top:3rem}
