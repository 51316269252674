.Wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.Right {
  width: 30%;
  background: #fff;
  border-radius: .5rem;
  padding: 1rem;
  img {
    display: block;
    border-radius: .5rem;
  }
}

@media (prefers-color-scheme: dark) {
  .Right {
    background: #1f1e1e;
  }
}

.Left {
  width: calc(70% - 1rem);
}

.LeftItem {
  display: flex;
  justify-content: space-between;
  background: #fff;
  border-radius: .5rem;
  margin-bottom: 1.5rem;
  &:last-child {
    margin-bottom: 0;
  }
}

@media (prefers-color-scheme: dark) {
  .LeftItem {
    background: #222222;
  }
}

.Item {
  > h3 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: #196f21;
  }
  p {
    font-weight: 400;
    font-size: 1.3rem;
  }
  ul {
    li {
      list-style: none;
      font-weight: 600;
      line-height: 150%;
      font-size: 1.3rem;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        font-weight: 400;
        font-size: 1.25rem;
      }
    }
  }
  a {
    display: block;
    color: #196f21;
    font-weight: 600;
    font-size: 1.3rem;
    margin-top: 2rem;
    &:hover {
      text-decoration: none;
    }
  }
}

@media (prefers-color-scheme: dark) {
  .Item {
    > h3 {
      color: #9a8e69;
    }
    p {
      color: #fff;
      opacity: .5;
    }
    ul {
      li {
        color: #fff;
        opacity: .5;
      }
    }
  }
}

.Date {
  max-width: 20rem;
  display: flex;
  justify-content: space-between;
}

.Pay {
  width: 30%;
  background: #f7f9fb;
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
  .Item {
    width: 100%;
    padding: 2rem;
  }
}

@media (prefers-color-scheme: dark) {
  .Pay {
    background: #1f1e1e;
  }
}

.Info {
  width: 69%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .Item {
    width: 48%;
    padding: 2rem;
  }
}