.Item {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  &:before {
    content: '';
    position: relative;
    top: -10px;
    display: block;
    width: .5rem;
    height: .5rem;
    margin-right: .5rem;
    border-radius: 100%;
  }
}

.Paid {
  color: #196f21;
  &:before {
    background: #196f21;
  }
}

.Paid {
  color: #196f21;
  &:before {
    background: #196f21;
  }
}

.Waiting {
  color: orange;
  &:before {
    background: orange;
  }
}