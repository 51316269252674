.Online {
  display: inline-block;
  width: .8rem;
  height: .8rem;
  background: #2E9039;
  border-radius: 100%;
}

.Offline {
  display: inline-block;
  width: .8rem;
  height: .8rem;
  background: #BE4906;
  border-radius: 100%;
}