.Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #197122 url('../../assets/img/background.png') no-repeat center center;
  background-size: cover;
}

.Form {
  width: 40rem;
  background: #fff;
  padding: 3rem 4rem;
  border-radius: .5rem;
  h2 {
    font-size: 2.4rem;
    font-weight: 600;
    color: #197323;
    margin-bottom: .75rem;
  }
  p {
    color: #333;
    font-size: 1.4rem;
  }
}