.Item {
  border: 1px solid #D3D3D3;
  width: 100%;
  height: 3.9rem;
  color: #333;
  font-size: 1.4rem;
  font-weight: 600;
  border-radius: .25rem;
  padding: 0 1.25rem;
  &:focus {
    border: 1px solid #333;
  }
  &::placeholder {
    color: #AFAFAF;
    font-size: 1.4rem;
    font-weight: 400;
  }
}

@media (prefers-color-scheme: dark) {
  .Item {
    border: 1px solid #000;
    background: #121212;
  }
}

.Message {
  width:  100%;
  display: block;
  color: red;
  padding-top: .5rem;
  font-size: 1.2rem;
}

.File {
  display: flex;
  cursor: pointer;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #AFAFAF;
  height: 4rem;
  font-size: 1.3rem;
  font-weight: 600;
  background: #fff;
  padding-left: 1.25rem;
  border: 1px solid #D3D3D3;
  border-radius: .5rem;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    background: #fff;
  }
  &:focus {
    border: 2px solid #197323;
  }
  &:disabled {
    cursor: no-drop;
    background: #ebebeb;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #222;
    padding: 0 2rem;
    background: #D3D3D3;
    img {
      margin-right: 1rem;
      width: 1.85rem;
      opacity: .4;
    }
  }
}


.FileChanged {
  color: #333;
}


[type=file] {
  position: absolute;
  filter: alpha(opacity=0);
  opacity: 0;
}

.Error .File {
  border: 1px solid #FF0000;
}

.ErrorSelect > div {
  border-color: #ff0000;
}


.Error .Message {
  display: block;
}

.Group {
  position: relative;
  display: block;
  margin-top: 1.25rem;
}
